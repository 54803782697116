import config from '../Config/config'
import axios from 'axios'

const headers = {
    headers: {
        'Content-Type': 'application/json'
    },
	withCredentials: true
}

const handleError = (e) => {
	console.log(e)
    return {
        data: {
            error: true,
            message: 'The server is currently unavailable. Please try again later.'
        }
    }
}

const createBillingPortalLink = async () => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/stripe/create-billing-portal-link', {}, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

const createCheckoutSession = async (body) => {
    try {
        const response = await axios.post(config.API_BASE_URL + '/stripe/create-checkout-session', body, headers)

        return response
    } catch (error) {
        return handleError()
    }
}

export { createBillingPortalLink, createCheckoutSession }